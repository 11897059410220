import { Button, Modal, Form, DatePicker, message, Row, AttributeValue, TableColumn, Table, Col, ButtonProps, Drawer } from '@tencent/tea-component';
import React, { useState, useEffect, useRef } from 'react';
import TableComponent from '@/components/Table';
import { ActionType } from '@tencent/tea-material-pro-table';
import { tableDataReqGen } from '@/utils/request';
import { useTableFilterGetResult } from '@/components/Table/utils/hooks';
import { IReqListQueries } from '@/types/request';
import moment from 'moment';
import { isValidHttpUrl } from '@/utils/isValidHttpUrl';

import createJssHook from '@/utils/jss';
import { querySeedsOverview, querySeedsList, deleteSeeds } from '../services/customer';

import SeedMutateBtn from './SeedMutateBtn'
import ValueMutateBtn from './ValueMutateBtn'
import { ICustomer, ISeed } from '../types/index';
interface IProps extends ButtonProps {
  customer: ICustomer
}

const SEED_TYPE: any = {
  Ip: 'IP',
  Domain: '域名',
  Icon: '图标',
  Title: '标题',
}

const defaultDate: any = {
  CreateAtStart: '',
  CreateAtEnd: '',
}

const useStyle = createJssHook({
  tableComponent: {
    '& .tea-layout__content,& .app-em-layout__content': {
      background: '#fff'
    },
    '& .tea-layout__content-body,& .app-em-layout__content-body': {
      padding: 0
    },
    '& .tea-datepicker__input,& .app-em-datepicker__input': {
      width: '100%'
    }
  }
});
const MutateBtn: React.FC<IProps> = ({ customer, ...btnProps }) => {
  const classes = useStyle();
  const [visible, setVisible] = useState(false);
  const [seedsList, setSeedsList] = useState<any[]>([]);
  const [params, setParams] = useState<IReqListQueries>({});
  const [category, setCategory] = useState<string>('');
  const [formValue, setFormValue] = useState(defaultDate);

  const actionRef = useRef<ActionType>();

  const columns1: TableColumn<ISeed>[] = [
    {
      key: 'Number',
      header: '序号',
      width: 50
    },
    {
      key: 'Category',
      header: '类型',
      width: 80,
      render: (record) => {
        const newWord = record.Category[0].toUpperCase() + record.Category.substr(1)
        return SEED_TYPE[newWord]
      }
    },
    {
      key: 'Value',
      header: '值',
      render: (record) => {
        return record.Category === 'icon' && record.Value && isValidHttpUrl(record.Value) ? <img alt='' src={record.Value} width='28' height='28'></img> : record.Value
      }
    }
  ];

  const columns2: TableColumn<ISeed>[] = [
    {
      key: 'Md5',
      header: 'MD5',
    }
  ];
  const columns3: TableColumn<ISeed>[] = [
    {
      key: 'Source',
      header: '来源',
      width: 80,
    },
    {
      key: 'CreateAt',
      header: '时间',
    },
    {
      key: 'Action',
      header: '操作',
      width: 100,
      align: 'left',
      render: record => {
        return (
          <>
            <ValueMutateBtn type='link' record={record} onSuccess={onSuccess}>编辑</ValueMutateBtn>
            <Button type='link' onClick={() => onDelete(record)}>
              删除
            </Button>
          </>
        );
      },
      fixed: 'right',
    },
  ];

  const onDelete = async (seed: ISeed) => {
    Modal.confirm({
      message: `确定要删除吗？`,
      description: ``,
      onOk: async () => {
        const resp = await deleteSeeds({ Ids: [seed.Id] });
        if (!resp.Response?.Error) {
          message.success({ content: '删除成功！' });
          onSuccess();
          return Promise.resolve();
        }
        return Promise.reject();
      },
    });
  };

  const attributes: AttributeValue[] = [
    // {
    //   type: 'single',
    //   key: 'Category',
    //   name: '类型',
    //   values: [
    //     { key: 'IP', name: 'IP' },
    //     { key: 'Domain', name: '域名' },
    //     { key: 'Icon', name: '图标' },
    //     { key: 'Title', name: '标题' },
    //   ],
    // },
    {
      type: 'input',
      key: 'Value',
      name: '值',
    },
    {
      type: 'input',
      key: 'Source',
      name: '来源',
    },
  ];

  const onSuccess = () => {
    actionRef.current?.reload();
  };

  const onClick = () => {
    setVisible(true);
    btnProps.onClick?.();
  };

  const onClose = () => {
    setVisible(false);
  };

  const queryOverview = async () => {
    const resp = await querySeedsOverview({ CustomerId: customer.Id });
    if (!resp.Response?.Error) {
      const seeds: any = resp.Response
      setSeedsList([
        { Type: 'Ip', Count: seeds.Ip },
        { Type: 'Domain', Count: seeds.Domain },
        { Type: 'Icon', Count: seeds.Icon },
        { Type: 'Title', Count: seeds.Title }])
    }
  };

  const onFormChange = (value: any, key: string) => {
    let params = defaultDate
    if (key === 'createAt' && value[0]) {
      params = {
        CreateAtStart: moment(value[0]).format('YYYY-MM-DD 00:00:00'), CreateAtEnd: moment(value[1]).format('YYYY-MM-DD 23:59:59')
      }
    }
    setFormValue((v: any) => ({ ...v, ...params }));
  };

  useEffect(() => {
    if (visible) {
      queryOverview()
    } else {
      setCategory('')
    }
    // eslint-disable-next-line 
  }, [visible]);

  useEffect(() => {
    formValue.CreateAtStart && actionRef.current?.reload();
    // eslint-disable-next-line 
  }, [formValue.CreateAtStart]);


  return (
    <>
      <Button {...btnProps} type='link' onClick={onClick} />
      <Drawer
        size={'l'}
        visible={visible}
        title="种子管理"
        footer={null}
        showMask
        onClose={onClose}
      >
        <Row>
          <Col span={20}>
            <h3 style={{ marginBottom: 15 }}>种子信息</h3>
          </Col>
          <Col >
            <SeedMutateBtn type='primary' customerId={customer.Id} customerName={customer.Name} onSuccess={() => queryOverview()}>上传种子</SeedMutateBtn>
          </Col>
        </Row>
        <Table
          bordered={true}
          disableTextOverflow={true}
          compact={true}
          records={seedsList}
          recordKey="Type"
          columns={[
            {
              key: "Type",
              header: "类型",
              render: (record) => {
                return <div onClick={() => {
                  setCategory(record.Type)
                  setParams(prev => ({
                    ...prev,
                    Filters: [{
                      Name: 'Category',
                      Values: [
                        record.Type
                      ]
                    }]
                  }))
                }}>
                  <Button type='link'>{SEED_TYPE[record.Type]}</Button>
                </div>
              }
            },
            {
              key: "Count",
              header: "个数",
            },
          ]}
        />
        {
          category && <>
            <hr />
            <div className={classes.tableComponent}>
              <TableComponent<any>
                recordKey='Id'
                actionRef={actionRef}
                columns={category === 'Icon' ? columns1.concat(columns2, columns3) : columns1.concat(columns3)}
                request={tableDataReqGen(async (params) => {
                  return await querySeedsList({ ...params, CustomerId: customer.Id, ...formValue });
                })}
                params={params}
                /* eslint-disable */
                operations={[
                  {
                    render: () => <Form layout="inline" style={{
                      position: 'absolute',
                      top: 0,
                      left: -9
                    }}
                    >
                      <Form.Item align="middle" label="">
                        <DatePicker.RangePicker style={{ width: 250 }} clearable onChange={(value) => onFormChange(value, 'createAt')} />
                      </Form.Item>
                    </Form>,
                  },
                  useTableFilterGetResult(
                    {
                      attributes,
                    },
                    filters => {
                      setParams(prev => ({
                        ...prev,
                        Filters: [...filters, {
                          Name: 'Category',
                          Values: [
                            category
                          ]
                        }].filter((item, index, arr) => {
                          return arr.findIndex(t => t.Name === item.Name) === index;
                        })
                      }));
                    }
                  ),
                ]}
              />
            </div>
          </>
        }
      </Drawer>
    </>
  );
};

export default MutateBtn;
