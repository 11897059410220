import { Button, ButtonProps, Input, Upload, RadioGroup, Radio, Form, message, Modal } from '@tencent/tea-component';
import { FormApi } from 'final-form';
import React, { useState, useRef } from 'react';
import { useField, useForm } from 'react-final-form-hooks';

import getStatus from '@/utils/form';
import createJssHook from '@/utils/jss';
import { importSeeds, createSeeds } from '../services/customer';
import { FILE_UPLOAD_URL } from '@/constants/common';

interface IProps extends ButtonProps {
  customerId: number;
  customerName: string;
  onSuccess?: () => void;
}

const { Item } = Form;
const useStyle = createJssHook({
  form: {
    '& .tea-input, & .tea-dropdown, & .tea-textarea': {
      width: '100%',
    },
    '& .app-em-input, & .app-em-dropdown, & .app-em-textarea': {
      width: '100%',
    },
    '& .tea-form-upload__thumb,& .app-em-form-upload__thumb': {
      width: 70,
      height: 70
    },
    '& .tea-form-upload__info,& .app-em-form-upload__info': {
      height: 70
    }
  },

});
const MutateBtn: React.FC<IProps> = ({ customerId, customerName, onSuccess, ...btnProps }) => {
  const classes = useStyle();
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState<any>(null);
  const [icon, setIcon] = useState<any>(null);
  const [image, setImage] = useState(undefined);

  const xhrRef: any = useRef(null);

  function handleStart(file: any, { xhr }: any) {
    setFile(file);
    xhrRef.current = xhr;
  }

  function handleIconStart(file: any, { xhr }: any) {
    getBase64(file);
    setIcon(file);
    xhrRef.current = xhr;
  }

  function getBase64(file: any) {
    const reader: any = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  }

  function handleIconSuccess(result: any) {
    form.change('Icon', result.Response.FileIdList[0])
  }

  function handleSuccess(result: any) {
    // console.log(result);
    form.change('FileId', result.Response.FileIdList[0])
  }

  function handleError() {
    handleAbort();
    Modal.alert({
      type: "error",
      message: "错误",
      description: "请求服务器失败",
    });
  }

  function handleAbort() {
    if (xhrRef.current) {
      xhrRef.current.abort();
    }
    setFile(null);
    form.change('FileId', undefined)
  }

  const onClick = () => {
    setVisible(true);
    btnProps.onClick?.();
  };


  const onSubmit = async (
    formData: Partial<any>,
    formInst: FormApi
  ) => {
    const FormData = {
      ...formData,
      CustomerId: customerId,
    } as any;
    // delete FormData.Way
    // delete FormData.Icon
    const resp = formData.Way === 'input' ?
      await createSeeds({
        ...FormData,
        Ips: formData.Ips?.split(','),
        Titles: formData.Titles?.split(','),
        Domains: formData.Domains?.split(','),
        Icons: formData.Icon?.split(','),
        // Icons: formData.Icon ? [formData.Icon] : undefined
      })
      : await importSeeds({ ...FormData, FileId: formData.FileId })
    if (!resp.Response?.Error) {
      message.success({
        content: '新增成功！',
      });
      setVisible(false);
      setFile(null)
      formInst.restart();
      onSuccess?.();
    }
  };
  const { form, handleSubmit, validating, submitting } = useForm<Partial<Partial<any>>>({
    onSubmit,
    initialValuesEqual: () => true,
    initialValues: {
      Way: 'input'
    },
    validate: formData => {
      return ({
        FileId: formData.Way === 'upload' && !formData.FileId ? '请选择文件' : undefined,
      })
    },
  });
  const customerIdField = useField('CustomerId', form);
  const IpsField = useField('Ips', form);
  const fileIdField = useField('FileId', form);
  const IconField = useField('Icon', form);
  const wayField = useField('Way', form);
  const DomainsField = useField('Domains', form);
  const TitlesField = useField('Titles', form);

  const onClose = () => {
    setVisible(false);
    setIcon('')
    setFile('')
    form.restart();
  };

  return (
    <>
      <Button {...btnProps} onClick={onClick} />
      <Modal visible={visible} caption={'上传种子'} onClose={onClose}>
        <form onSubmit={handleSubmit} >
          <Modal.Body>
            <Form className={classes.form}>
              <Item
                required
                label='方式'
              >
                <RadioGroup  {...wayField.input}>
                  <Radio name="input">手工录入</Radio>
                  <Radio name="upload">批量导入</Radio>
                </RadioGroup>
              </Item>
              <Item required label='企业'>
                <Form.Text {...customerIdField.input}>{customerName || '-'}</Form.Text>
              </Item>
              {
                wayField.input.value === 'input' ? <>
                  <Item
                    label='IP'
                  >
                    <Input
                      {...IpsField.input}
                      placeholder=''
                    />
                  </Item>
                  <Item
                    label='域名'
                  >
                    <Input
                      {...DomainsField.input}
                      placeholder=''
                    />
                  </Item>
                  <Item
                    label='图标'
                  >
                    <Input
                      {...IconField.input}
                      placeholder=''
                    />
                    {/* <Upload
                      {...IconField.input}
                      withCredentials
                      name='File'
                      action={FILE_UPLOAD_URL}
                      accept=".png, .jpg, .jpeg"
                      onStart={handleIconStart}
                      onSuccess={handleIconSuccess}
                      onError={handleError}
                    >
                      {({ open }) => (
                        <Upload.Image
                          filename={icon && icon.name}
                          image={
                            icon
                              ? image
                              : "//imgcache.qq.com/open_proj/proj_qcloud_v2/tea-style/dist/assets/image/upload-thumb.png"
                          }
                          button={
                            getStatus(IconField.meta, validating) === "validating" ? (
                              <Button htmlType='button' onClick={handleAbort}>取消上传</Button>
                            ) : (
                              <>
                                <Button htmlType='button' onClick={open}>
                                  选择图片
                                </Button>
                              </>
                            )
                          }
                        />
                      )}
                    </Upload> */}
                  </Item>
                  <Item
                    label='标题'
                  >
                    <Input
                      {...TitlesField.input}
                      placeholder=''
                    />
                  </Item>
                </> : <Item
                  label='文件'
                  required
                  status={getStatus(fileIdField.meta, validating)}
                  message={<div>
                    下载{' '}
                    <a
                      target="_blank"
                      download="新增数据示例模版.xlsx"
                      href={`/em/files/tencent_em.xlsx`} rel="noreferrer"
                    >
                      新增数据示例模版
                    </a>{' '}
                    填写之后上传文档即可
                  </div>}
                >
                  <Upload
                    {...fileIdField.input}
                    withCredentials
                    action={FILE_UPLOAD_URL}
                    name='File'
                    accept=".xlsx"
                    onStart={handleStart}
                    onSuccess={handleSuccess}
                    onError={handleError}
                  >
                    {({ open }) => (
                      <Upload.File
                        filename={file && file.name}
                        button={
                          getStatus(fileIdField.meta, validating) === "validating" ? (
                            <Button htmlType='button' onClick={handleAbort}>取消上传</Button>
                          ) : (
                            <>
                              <Button htmlType='button' onClick={open}>
                                {"选择文件"}
                              </Button>
                              {fileIdField.input.value && (
                                <Button
                                  type="link"
                                  htmlType='button'
                                  style={{ marginLeft: 8 }}
                                  onClick={handleAbort}
                                >
                                  删除
                                </Button>
                              )}
                            </>
                          )
                        }
                      />
                    )}
                  </Upload>
                </Item>
              }
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button type='primary' loading={submitting} disabled={submitting}>
              确定
            </Button>
            <Button type="weak" htmlType='button' onClick={onClose}>
              取消
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default MutateBtn;
