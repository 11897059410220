import { IReqListQueries, IRespList, IResponse } from '@/types/request';
import request from '@/utils/request';
import { ICustomer, ISeed } from '../types';

export const queryCustomerList = async (queries: IReqListQueries) =>
  request<IRespList<ICustomer>>('/customer/DescribeCustomers', {
    method: 'POST',
    data: queries,
  });

export const mutateCustomer = async (user: ICustomer) =>
  request<IResponse>(user.Id ? '/customer/ModifyCustomer' : '/customer/CreateCustomer', {
    method: 'POST',
    data: user,
  });

export const createJobRecord = async (params: { CustomerId: number, ScanType: string, TaskType: string }) =>
  request<IRespList<ICustomer>>('/job/CreateJobRecord', {
    method: 'POST',
    data: params,
  });
export const queryComponentsList = async (queries: IReqListQueries) =>
  request<IRespList<ICustomer>>('/job/DescribeComponents', {
    method: 'POST',
    data: queries,
  });

export const querySeedsOverview = async (params: { CustomerId: number }) =>
  request<IResponse>('/seed/DescribeSeedOverview', {
    method: 'POST',
    data: params,
  });

export const importSeeds = async (params: any) =>
  request<IResponse>(`/seed/ImportSeeds`, {
    method: 'POST',
    data: params,
  });

export const createSeeds = async (params: any) =>
  request<IResponse>('/seed/CreateSeeds', {
    method: 'POST',
    data: params,
  });

export const modifySeed = async (params: any) =>
  request<IResponse>('/seed/ModifySeed', {
    method: 'POST',
    data: params,
  });

export const querySeedsList = async (queries: IReqListQueries) =>
  request<IRespList<ISeed>>('/seed/DescribeSeeds', {
    method: 'POST',
    data: queries,
  });

export const deleteSeeds = async (params: { Ids: number[] }) =>
  request<IRespList<any>>(`/seed/DeleteSeeds`, {
    method: 'POST',
    data: params,
  });

export const deleteCustomer = async (params: { Id: number }) =>
  request<IRespList<any>>(`/customer/DeleteCustomer`, {
    method: 'POST',
    data: params,
  });





