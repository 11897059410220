import { IAppRoute } from '@/types/app';
import CustomerList from '@/pages/customer/List';
import { RISK_MAP } from '@/constants/riskMap';

const routes: IAppRoute[] = [
  // {
  //   // 由于要同时兼容私有化与公有云，路由的定义尽量不要过于复杂，
  //   // 路由之间如果需要传参数，需要使用 /path?key=value 的形式
  //   path: '/system',
  //   name: '系统管理',
  //   // 内置 icon 可参考 https://tea.tencent.com/component/icon
  //   // 自定义 icon 映射在 src/constants/customIconMap 中维护
  //   icon: 'system',
  //   // 是否在菜单中隐藏，适用于类似详情页这种不需要在菜单中显示的路由
  //   hideInMenu: true,
  //   routes: [
  //     {
  //       path: '/user',
  //       name: '用户管理',
  //       component: './pages/user/List',
  //     },
  //   ],
  // },
  {
    path: '/overview',
    name: '安全概览',
    icon: 'monitor',
    component: './pages/overview/Index',
  },
  {
    path: '/asset',
    name: '资产',
    icon: 'database',
    routes: Object.keys(RISK_MAP)
      .slice(0, 12).map(key => {
        return {
          path: `/asset/${key}`,
          name: RISK_MAP[key].moduleKey,
          component: './pages/data/Index',
        }
      })
    // [
    //   {
    //     path: '/asset/enterprise',
    //     name: '企业架构',
    //     component: './pages/data/Index',
    //   },
    // ],
  },
  {
    path: '/asset',
    name: '风险',
    icon: 'viewlist',
    routes: [
      {
        path: '/vul',
        name: '漏洞情报',
        component: './pages/vul/Index',
      }
    ].concat(Object.keys(RISK_MAP)
      .slice(-7).map(key => {
        return {
          path: `/asset/${key}`,
          name: RISK_MAP[key].moduleKey,
          component: './pages/data/Index',
        }
      }))
  },
  {
    path: '/asset',
    name: '配置',
    icon: 'setting',
    routes:
      [
        {
          path: '/customer-mng',
          name: '企业管理',
          // icon: 'relationship',
          component: CustomerList,
        },
        {
          path: '/tools',
          name: '工具管理',
          // icon: 'database',
          component: './pages/tools/Index',
        },
        {
          path: '/task',
          name: '任务管理',
          // icon: 'viewlist',
          component: './pages/task/Index',
        },
        {
          path: '/set',
          name: '黑名单设置',
          // icon: 'setting',
          component: './pages/scan/Index',
        },
        {
          path: '/user-mng',
          name: '用户管理',
          // icon: 'realname',
          component: './pages/user/List',
        },
      ],
  },
  // {
  //   path: '/data-mng',
  //   name: '数据管理',
  //   icon: 'datasheet',
  //   component: './pages/data/Index',
  // },
];

export default routes;
