import { API_BASE_URL } from "@/utils/request";
import Nginx from '@/assets/icons/tag/Nginx.svg';
import Jquery from '@/assets/icons/tag/Jquery.svg';
import Windows from '@/assets/icons/tag/Windows.svg';
import java from '@/assets/icons/tag/java.svg';
import python from '@/assets/icons/tag/python.svg';
import apache from '@/assets/icons/tag/apache.svg';
import Bootstrap from '@/assets/icons/tag/Bootstrap.svg';
import centos from '@/assets/icons/tag/centos.svg';
import gitlab from '@/assets/icons/tag/gitlab.svg';
import OpenResty from '@/assets/icons/tag/OpenResty.svg';
import iis from '@/assets/icons/tag/iis.svg';
import Oracle from '@/assets/icons/tag/Oracle.svg';
import Ruby from '@/assets/icons/tag/ruby.svg';
import HSTS from '@/assets/icons/tag/HSTS.svg';
import Sangfor from '@/assets/icons/tag/sangfor.svg';
import Huawei from '@/assets/icons/tag/huawei.svg';
import WindowsServer from '@/assets/icons/tag/WindowsServer.svg';
import MicrosoftASPNET from '@/assets/icons/tag/Microsoft ASP.NET.svg';
import tencentCos from '@/assets/icons/tag/tencent-cos.svg';


/** 上传文件 */
export const FILE_UPLOAD_URL = API_BASE_URL + '/user/UploadFile';

export const TAG_ICON_MAP: Record<string, string> = {
    Nginx: Nginx,
    jQuery: Jquery,
    Windows: Windows,
    Java: java,
    python,
    Apache: apache,
    Bootstrap,
    centos,
    gitlab,
    OpenResty,
    IIS: iis,
    Oracle,
    Ruby,
    HSTS,
    Sangfor,
    Huawei,
    'Windows Server': WindowsServer,
    'ASP': MicrosoftASPNET,
    'tencent-cos': tencentCos
}