import {
  Layout,
  TableColumn,
  Button,
  Modal,
  message,
  Checkbox
} from '@tencent/tea-component';
import { ActionType } from '@tencent/tea-material-pro-table';
import React, { useRef, useState } from 'react';
import moment from 'moment';

import AvatarDropdown from '@/components/AvatarDropdown';
import Table from '@/components/Table';
import { tableDataReqGen } from '@/utils/request';
import MutateBtn from './components/MutateBtn';
import SeedBtn from './components/SeedBtn';
import { queryCustomerList, createJobRecord, deleteCustomer } from './services/customer';
import { ICustomer } from './types';

const { Body, Content } = Layout;

const List: React.FC = () => {
  const actionRef = useRef<ActionType>();
  const [visible, setVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const [mode, setMode] = useState<string[]>([]);
  const onSuccess = () => {
    actionRef.current?.reload();
  };

  const open = () => setVisible(true);
  const close = () => setVisible(false);

  const onJobRecord = async (customer: ICustomer) => {
    Modal.confirm({
      message: `确定启动测绘？`,
      description: ``,
      onOk: async () => {
        const resp = await createJobRecord({
          CustomerId: customer.Id,
          ScanType: customer.ScanType as unknown as string,
          TaskType: '即时任务'
        });
        if (!resp.Response?.Error) {
          message.success({ content: '操作成功！' });
          onSuccess();
          return Promise.resolve();
        }
        return Promise.resolve();
      },
    });
  };

  const onOk = async () => {
    if (mode.length !== 3) {
      message.error({
        content: "请先确认完成以下事项",
      })
      return
    }
    const resp = await createJobRecord({
      CustomerId: currentRecord.Id,
      ScanType: currentRecord.ScanType as unknown as string,
      TaskType: '即时任务'
    });
    if (!resp.Response?.Error) {
      message.success({ content: '操作成功！' });
      setMode([])
      onSuccess();
      close()
    }
  }

  const onDelete = async (record: ICustomer) => {
    Modal.confirm({
      message: `确定要删除吗？`,
      description: ``,
      onOk: async () => {
        const resp = await deleteCustomer({ Id: record.Id });
        if (!resp.Response?.Error) {
          message.success({ content: '删除成功！' });
          onSuccess();
          return Promise.resolve();
        }
        return Promise.resolve();
      },
    });
  };

  const columns: TableColumn<ICustomer>[] = [
    {
      key: 'Id',
      header: '企业ID',
      width: 150,
    },
    {
      key: 'Name',
      header: '企业名称',
    },
    {
      key: 'Percent',
      header: '子公司股权占比条件',
      render: ({ Percent }) => `${Percent}%`
    },
    {
      key: 'ScanType',
      header: '测绘功能',
    },
    {
      key: 'AuthStartAt',
      header: '授权扫描时间',
      render: ({ AuthStartAt, AuthEndAt }) => AuthStartAt ? <div>
        <span>{AuthStartAt}</span>
        <div>——</div>
        <span>{AuthEndAt}</span>
      </div> : '-'
    },
    {
      key: 'Creator',
      header: '创建账号',
    },
    {
      key: 'ScanCron',
      header: '周期扫描',
      render: ({ ScanCron }) => {
        const valueSplited = ScanCron?.split('#');
        let period = valueSplited[0];
        let value = valueSplited[1]?.split(' ');
        let scanCron = ''
        if (value) {
          switch (period) {
            case '每日':
              scanCron = `${period},${moment(`2020-01-01 ${value[2]}:${value[1]}:${value[0]}`).format('HH:mm')}`
              break;
            case '每周':
              // 每周、每月有两个选项，用数组表示对应的两个vi
              scanCron = `${period}${['一', '二', '三', '四', '五', '六', '日'][(+value[5] === 0 ? 6 : +value[5] - 1)]},${moment(`2020-01-01 ${value[2]}:${value[1]}:${value[0]}`).format('HH:mm')}`
              break;
            case '每月':
              scanCron = `${period}${[+value[3]]}日,${moment(`2020-01-01 ${value[2]}:${value[1]}:${value[0]}`).format('HH:mm')}`
              break;
          }
        }
        return scanCron || '-'
      }
    },
    {
      key: 'CreateAt',
      header: '创建时间',
      width: 165,
      align: 'center',
    },
    {
      key: 'Action',
      header: '操作',
      width: 150,
      align: 'center',
      render: record => (
        <>
          <MutateBtn type='link' customer={record} onSuccess={onSuccess}>
            编辑
          </MutateBtn>
          <Button type='link' onClick={() => {
            if (window.location.href.includes('woa.com')) {
              open()
              setCurrentRecord(record)
            } else {
              onJobRecord(record)
            }
          }}>
            启动测绘
          </Button>
          <div>
            <SeedBtn customer={record}>种子管理</SeedBtn>
            <Button type='link' onClick={() => onDelete(record)}>
              删除
            </Button>
          </div>
        </>
      ),
    },
  ];


  return (<>
    <Body>
      <Content>
        <Content.Header title='企业管理' operation={<AvatarDropdown />} />
        <Content.Body>
          <Table<ICustomer>
            recordKey='Id'
            actionRef={actionRef}
            columns={columns}
            request={tableDataReqGen(queryCustomerList)}
            operations={[
              {
                render: () => (
                  <MutateBtn type='primary' onSuccess={onSuccess}>
                    新建企业
                  </MutateBtn>
                ),
              },
            ]}
          />
        </Content.Body>
      </Content>
    </Body>
    <Modal
      disableEscape
      visible={visible}
      caption="启动测绘前，请先确认已经完成以下事项："
      onClose={close}
    >
      <Modal.Body>
        <Checkbox.Group
          value={mode}
          onChange={value => setMode(value)}
          layout="column"
        >
          <Checkbox name="1" >
            已取得客户允许扫描的书面授权
          </Checkbox>
          <Checkbox name="2" >
            已在客户群里同步扫描注意事项，并得到了客户同意扫描的回复
          </Checkbox>
          <Checkbox name="3">
            知晓未经客户授权和同意，擅自启动测绘将触犯<span style={{ color: 'red' }}>公司高压线</span>
          </Checkbox>
        </Checkbox.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button type="primary" onClick={onOk}>
          确定
        </Button>
        <Button type="weak" onClick={close}>
          取消
        </Button>
      </Modal.Footer>
    </Modal>
  </>
  );
};

export default List;
